import React from 'react'

export default function NotFound() {
  return (
    <div>
      <h1>404 aka PAGE NOT FOUND</h1>
      <p>here we should place a button</p>
    </div>
  )
}
